@import 'inventory.scss';

.card-cyclotron-product-list-item-header {
	align-items: center;
	background-color: transparent;
	display: flex;
	flex-grow: 1;
	justify-content: space-between;

	&__content {
		.tag {
			flex-shrink: 0;
			margin: $spacing-xs $spacing-s 0 ($spacing-s * 2);
		}
	}

	&__title-wrapper {
		align-items: flex-start;
		display: flex;
	}

	&__title {
		@include headingLevel2;

		hyphens: auto;
		margin: 0;
		padding: 0;
	}

	&__subtitle {
		@include captionSmall;

		margin: 0;
		padding: 0;
	}
}
