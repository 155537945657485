@import 'inventory.scss';

.simple-view-title {
	@include headingLevel1;
	padding-bottom: $spacing-m;

	&--is-report-view {
		padding-top: $spacing-s * 3;
		padding-bottom: $spacing-s;
	}
}
