/* nunito-regular - latin */
@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 400;
	src:
		url('/assets/fonts/nunito-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		url('/assets/fonts/nunito-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-600 - latin */
@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 600;
	src:
		url('/assets/fonts/nunito-v12-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		url('/assets/fonts/nunito-v12-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-700 - latin */
@font-face {
	font-display: swap;
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 700;
	src:
		url('/assets/fonts/nunito-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		url('/assets/fonts/nunito-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
