@import 'src/presentation/sass/inventory.scss';

.modal-dialogue {
	&__background {
		position: fixed;
		width: 100vw;
		height: 100vh;
		left: 0;
		top: 0;

		opacity: 0.4;
		background-blend-mode: multiply;
		background-color: $primary-100;

		z-index: $zIndex-2000;
	}

	&__window {
		position: fixed;
		width: 90vw;
		max-width: 960px;
		height: 80vh;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);

		display: flex;
		flex-direction: column;
		align-content: stretch;

		text-align: left;

		border-radius: 16px;
		box-shadow: 0 6px 20px 0 rgba(23, 75, 129, 0.1);
		background-color: $white;

		overflow: hidden;
		z-index: $zIndex-2001;

		&--height-flex {
			height: auto;
			max-height: 80vh;
		}

		&__caption {
			display: flex;
			align-items: center;

			padding: ($spacing-xs * 7) ($spacing-s * 3) ($spacing-xs * 7) ($spacing-s * 5);
			background-color: $white;

			@include mediaQuery($breakpoint-sm, down) {
				padding: ($spacing-xs * 7) ($spacing-s * 3) ($spacing-xs * 7) ($spacing-s * 3);
			}

			.icon {
				margin-right: $spacing-s;

				@include mediaQuery($breakpoint-sm, down) {
					margin-left: -$spacing-s;
				}
			}

			&.window__caption--big {
				box-shadow: 0 6px 10px 0 rgba(23, 75, 129, 0.1);

				.icon {
					width: 32px;
					height: 32px;
				}

				.modal-dialogue__window__caption__text {
					@include headingLevel1Primary100;
				}

				~ .modal-dialogue-section--section-main {
					@include paragraphLeft;
				}
			}

			&.window__caption--small {
				.icon {
					color: $accent-error-100;
					width: 24px;
					height: 24px;
				}

				.modal-dialogue__window__caption__text {
					@include subtitleAttention;

					margin: 0;
					padding: 0;
				}

				~ .modal-dialogue-section--section-main {
					padding-top: 0;
				}

				+ .modal-dialogue-section--section-main {
					display: block;
				}
			}

			&.window__caption--grid {
				.modal-dialogue__window__caption__text {
					@include captionBold;

					margin: 0;
					padding: 0;
				}

				~ .modal-dialogue-section--section-main {
					padding-top: 0;
				}
			}
		}

		&__dismiss {
			height: 48px;
			margin-left: auto;
			position: absolute;
			right: ($spacing-s * 3);
			top: 20px;
			width: 48px;

			@include mediaQuery($breakpoint-sm, down) {
				right: ($spacing-xs * 3);
			}
		}

		&__stopper {
			height: 1px;
			width: 1px;
			background: transparent;
			border: none;
			outline: none;
			font-size: 0;

			&:focus-visible,
			:focus {
				border: none;
				outline: none;
			}
		}
	}
}
