@import 'inventory.scss';

.notification-bars {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 40px;

	&:empty {
		display: none;
	}

	&:blank {
		display: none;
	}
}
