@import 'inventory.scss';

.sequence-table-wrapper {
	position: relative;
	padding: $spacing-s;

	background-color: $primary-5;
	overflow: hidden;

	&__pane {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	.sequence-table {
		width: 100%;
		text-align: left;
		border-collapse: collapse;

		&__body {
			@include captionSemiBold;
			line-height: $line-height20;

			&__row {
				&__cell {
					position: relative;
					padding: $spacing-xs ($spacing-xs * 3) $spacing-s;
					background-color: $white;
					border: none;
					white-space: nowrap;

					&::after {
						display: block;
						position: absolute;
						content: "";
						background-color: $primary-5;
						width: 100%;
						height: 4px;
						left: 0;
						bottom: 0;
					}

					ul {
						margin: 0;
						padding: 0;
						list-style: none;
					}

					&__note {
						@include captionSmallBold;

						&--prefix {
							margin-right: 4px;
						}

						&--suffix {
							margin-left: 4px;
						}

						&:empty {
							display: none;
						}

						&:blank {
							display: none;
						}
					}

					&__value {
						&--record-acceptable {
							@include sequenceValueRecordValid;
							margin-right: ($spacing-xs * 3);
						}

						&--record-noticeable {
							@include sequenceValueRecordWarning;
							margin-right: ($spacing-xs * 3);
						}

						&--record-unacceptable {
							@include sequenceValueRecordError;
							margin-right: ($spacing-xs * 3);
						}

						&--soft-threshold {
							color: $accent-warning-110;
						}

						&--hard-threshold {
							color: $accent-error-110;
						}
					}

					&__actions {
						display: flex;
						align-items: center;

						&__value {
							flex-grow: 1;
						}
					}

					&--align-right {
						padding-right: ($spacing-s * 3);
						text-align: right;

						.sequence-table__body__row__cell__actions {
							justify-content: flex-end;
						}
					}

				}

				&--highlighted {
					.sequence-table__body__row__cell {
						background-color: $primary-10;
					}
				}

				&--replaced {
					.sequence-table__body__row__cell {
						background-color: $primary-5;

						> * {
							opacity: 0.5;
						}
					}
				}

			}
		}

		&__row__cell--sticky-start {
			position: sticky;
			left: 0;
			width: 1%;
			padding-right: ($spacing-xs * 5);
			z-index: $zIndex-1;

			&::before {
				display: block;
				position: absolute;
				content: "";
				background-color: $primary-5;
				height: 100%;
				width: 8px;
				right: 0;
				top: 0;
			}
		}

		&__row__cell--sticky-end {
			position: sticky;
			right: 0;
			width: 1%;
			padding-left: ($spacing-xs * 5);
			z-index: $zIndex-1;

			&::before {
				display: block;
				position: absolute;
				content: "";
				background-color: $primary-5;
				height: 100%;
				width: 8px;
				left: 0;
				top: 0;
			}
		}

	}
}
