@import 'inventory.scss';

.card-document-folder {
	background-color: $white;
	border-radius: 8px;
	box-shadow: 0 6px 20px 0 transparentize($primary-100, 0.9);
	display: flex;
	position: relative;

	&.card-document-folder--blue {
		background-color: $primary-5;
	}

	&__content {
		align-items: center;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: ($spacing-s * 3) ($spacing-s * 2) ($spacing-s * 3) ($spacing-s * 2);
		width: 100%;
	}

	.card-item-header {
		max-width: 72.972972973%;
	}
}
