@import 'src/presentation/sass/inventory.scss';

.generator-table-body {
	@include captionSemiBold;
	line-height: $line-height20;

	&__row {
		&__cell {
			position: relative;
			padding: $spacing-xs ($spacing-xs * 3) $spacing-xs;
			border: none;
			white-space: nowrap;
			background-color: $white;

			&::after {
				display: block;
				position: absolute;
				content: "";
				background-color: $background-light;
				width: 100%;
				height: 4px;
				left: 0;
				bottom: 0;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;
			}

			&__actions {
				display: flex;
				align-items: center;

				&__value {
					flex-grow: 1;
				}
			}

			&__value {
				display: block;
			}

			&--align-right {
				padding-right: ($spacing-s * 3);
				text-align: right;

				.generator-table-body__row__cell__actions {
					justify-content: flex-end;
				}
			}
		}
	}

	&__row__cell--sticky-start {
		position: sticky;
		left: 0;
		width: 1%;
		min-width: 105px;
		padding-right: ($spacing-xs * 5);
		z-index: $zIndex-1;
		margin-right: $spacing-s;

		&::before {
			display: block;
			position: absolute;
			content: "";
			background-color: $background-light;
			height: 100%;
			width: 8px;
			right: 0;
			top: 0;
		}
	}

	&__row__cell--sticky-end {
		position: sticky;
		right: 0;
		width: 1%;
		padding-left: ($spacing-xs * 5);
		z-index: $zIndex-1;

		&::before {
			display: block;
			position: absolute;
			content: "";
			background-color: $background-light;
			height: 100%;
			width: 8px;
			left: 0;
			top: 0;
		}
	}
}
