@import 'inventory.scss';

.sequence-chart-overlay {
	display: block;
	position: absolute;

	left: 50%;
	top: 40%;
	transform: translateX(-50%) translateY(-50%);

	padding: ($spacing-s * 2) ($spacing-m * 2) ($spacing-s * 2) ($spacing-s * 3);

	border-radius: 4px;

	z-index: $zIndex-100;

	box-shadow: 0 6px 20px 0 rgba(23, 75, 129, 0.1);
	background-color: $background-white;

	&__trigger {
		position: absolute;
		right: 4px;
		top: 4px;
		width: 48px;
		height: 48px;
	}

	&__properties {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 24px;
		row-gap: 24px;
		width: max-content;

		cursor: pointer;
	}

	&__property {
		text-align: left;
		overflow: hidden;

		&--full-width {
			grid-column-start: 1;
			grid-column-end: 4;
		}

		&__label {
			@include captionBold;

			display: block;
			margin-bottom: $spacing-xs;
			white-space: nowrap;

			cursor: inherit;
		}

		&__value {
			@include captionBold;
			font-size: $font-size12;
			line-height: $line-height20;

			display: inline-block;
			max-width: 100%;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;

			list-style: none;
			margin: 0;
			padding: 0;

			li {
				line-height: $line-height20;
			}

			&--record-acceptable {
				@include sequenceValueRecordValid;
			}

			&--record-noticeable {
				@include sequenceValueRecordWarning;
			}

			&--record-unacceptable {
				@include sequenceValueRecordError;
			}

			&--soft-threshold {
				color: $accent-warning-110;
			}

			&--hard-threshold {
				color: $accent-error-110;
			}
		}
	}
}
