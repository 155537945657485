@import 'src/presentation/sass/inventory.scss';

.external-waste-management-report-generators-card {
	background-color: $background-white;
	border-radius: $spacing-s;
	display: flex;
	gap: $spacing-s * 2;
	flex-direction: column;
	position: relative;
	padding: $spacing-s * 4;

	&__section {
		display: flex;
		flex-direction: column;
		gap: $spacing-s;

		&__header {
			@include headingLevel2;

			margin-bottom: $spacing-s;
		}
	}
}
