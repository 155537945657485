@import 'inventory.scss';

.card-maintenance-log-detail {
	background-color: $background-white;
	border-radius: 8px;

	&__content {
		align-items: center;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: ($spacing-s * 3) ($spacing-s * 2) ($spacing-s * 2) ($spacing-s * 2);
		width: calc(100% - #{$spacing-m});

		.card-item-operational-log-attributes {
			margin-top: $spacing-m;
		}
	}
}
