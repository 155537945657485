@import 'inventory.scss';

.card-item-device-state {
	background-color: transparent;

	&__entries-list {
		display: flex;
		list-style-type: none;
		margin: 0;
		padding: 0;
		text-align: left;
	}

	&__entry {
		box-sizing: border-box;
		display: inline-flex;
		padding: 0;

		&:first-of-type {
			padding-right: $spacing-s * 2;
		}

		&:last-of-type {
			padding-left: $spacing-s;
		}

		&:nth-child(2) {
			padding: 0 ($spacing-s * 2) 0 $spacing-s;
		}

		&:not(:last-of-type) {
			border-right: 1px solid $primary-100;
		}
	}

	.state-entry__value {
		@include paragraphLeft;

		hyphens: auto;
		margin: 0 0 0 $spacing-xs;
		padding-left: $spacing-xs;
	}

	.icon {
		flex-shrink: 0;
		margin-right: $spacing-s;
	}
}
