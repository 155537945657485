@import 'inventory.scss';

.navigation-primary {
	display: flex;
	flex-direction: column;
	background-color: $white;
	height: 100vh;
	padding: $spacing-m 0;

	&--open {
		width: 280px;
		box-shadow: 0 6px 20px 0 rgba(23, 75, 129, 0.1);

		.navigation-primary__logo {
			width: 280px;
			background-image: url('../../../../../assets/logo/logo-navigation-expand.webp');
		}

	}

	&--close {
		width: 80px;

		.navigation-primary__logo {
			width: 80px;
			background-image: url('../../../../../assets/logo/logo-navigation-compress.webp');
		}
	}

	.navigation-primary__logo {
		background-repeat: no-repeat;
		background-size: contain;
		background-position-x: center;
		height: 48px;
	}

	.navigation-primary__nav-items {
		display: flex;
		flex-direction: column;
		height: calc(100vh - 48px - #{$spacing-xs * 15} - #{$spacing-m * 2});
		margin-top: ($spacing-xs * 15);
		overflow-y: auto;
		overflow-x: hidden;

		a {
			text-decoration: none;
		}

		.button-icon:first-of-type,
		.button-ghost:first-of-type {
			margin-top: auto;
		}

		&--language-switch-closed {
			@include paragraphCentered;
			cursor: pointer;
			padding-top: ($spacing-xs * 7);
			align-self: center;
		}

		.button-ghost {
			justify-content: flex-start;
			margin-left: 24px;
		}

		.select {
			margin: ($spacing-s * 2) ($spacing-s * 3) 0 ($spacing-s * 3);
		}
	}
}
