@import 'src/presentation/sass/inventory.scss';

.checkbox {
	display: inline-flex;
	align-items: center;

	&__input {
		width: 20px;
		height: 20px;
	}

	&__label {
		@include formValueDefault;

		padding-left: $spacing-s;

		&__report-selection {
			padding-left: $spacing-s * 3;
		}
	}

	&.checkbox--summary {
		background-color: $primary-5;
		padding: 0 0 ($spacing-xs) ($spacing-xs * 2);
		pointer-events: none;

		&:last-child {
			padding-bottom: $spacing-xs * 3;
		}

		.checkbox__input {
			display: none;
		}

		.checkbox__label {
			@include summaryLabel;
		}
	}
}
