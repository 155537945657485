@import 'inventory.scss';

.sequence-chart {
	position: relative;
	padding: ($spacing-s * 2);
	background-color: #fff;

	&__visual {
		display: block;
		position: relative;
		background-color: $background-light;
	}

	&__legend {
		@include captionBold;

		padding: ($spacing-s * 2);
		text-align: left;

		&__terms {
			@include captionRegular;

			display: inline-block;
			margin: 0;
			padding: 0 0 0 ($spacing-s * 2);
			line-height: 24px;

			&__term {
				display: inline-block;
				list-style: none;
				margin-right: ($spacing-s * 2);
				vertical-align: baseline;

				&::before {
					display: inline-block;
					width: 10px;
					height: 10px;
					margin-right: $spacing-xs;
					background-color: #fff;
					content: "";
					vertical-align: baseline;
					border-radius: 50%;
				}

				&--value {
					&::before {
						width: 6px;
						height: 6px;
						background-color: $primary-7;
						border: 2px solid $primary-100;
					}
				}

				&--soft-threshold {
					&::before {
						background-color: $accent-warning-100;
					}
				}

				&--hard-threshold {
					&::before {
						background-color: $accent-error-100;
					}
				}

			}
		}
	}
}
