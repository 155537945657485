@import 'inventory.scss';

.label {
	&__labeltext {
		@include formLabel;

		align-items: center;
		display: inline-flex;
		height: 48px;
	}

	&.label--thin {
		.label__labeltext {
			@include formLabelSummary;
		}
	}

	&.label--align-top {
		@include formLabel;

		align-items: flex-start;
	}
}
