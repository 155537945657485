@import 'src/presentation/sass/inventory.scss';

.external-waste-management-report-cyclotron-product-annual-report {
	display: flex;
	flex-direction: column;
	gap: $spacing-s * 2;

	&__attributes {
		display: flex;
		flex-direction: column;

		.attribute {
			display: flex;
			flex-direction: row;

			strong {
				@include captionBold;

			}

			p {
				@include captionRegular;

				display: inline-block;
				hyphens: auto;
				margin: 0;
				padding: 0;
			}
		}
	}
}
