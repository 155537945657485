@import 'inventory.scss';

.textarea {
	&:hover:not(.textarea--disabled):not(.textarea--error) {
		.textarea__wrapper {
			border: solid 1px $primary-70;
		}
	}

	&:focus:not(.textarea--disabled):not(.textarea--error),
	&:focus-within:not(.textarea--disabled):not(.textarea--error) {
		.textarea__wrapper {
			border: solid 2px $primary-100;
			padding: ($spacing-xs * 3) 0;
		}
	}

	&.textarea--disabled {
		.textarea__wrapper {
			background-color: $white;
			border-color: $primary-10;

			textarea,
			textarea::placeholder {
				@include formValuePlaceholder;
			}
		}
	}

	&.textarea--error {
		.textarea__wrapper {
			background-color: $white;
			border-color: $accent-error-100;

			&:hover {
				border-color: $accent-error-100;
			}

			textarea {
				@include formValueNegative;
			}
		}

		.textarea__error-message {
			display: block;
		}
	}

	&.textarea--readonly {
		@include cursorDisabled;

		user-select: none;

		.textarea__wrapper {
			background-color: $primary-5;
			border: 0;

			textarea,
			textarea::placeholder {
				@include formValueReadonly;
			}
		}
	}

	&__wrapper {
		background-color: $white;
		border-radius: 8px;
		border: solid 1px $primary-30;
		box-sizing: border-box;
		min-height: 96px;
		min-width: 194px;
		outline: 0;
		padding: (($spacing-xs * 3) + 1) 0;

		textarea {
			@include formValueDefault;

			background: none;
			border: 0;
			box-sizing: border-box;
			min-height: 96px;
			min-width: 194px;
			outline: 0;
			padding: 0 ($spacing-s * 2);
			resize: none;
			width: 100%;

			&::placeholder {
				@include formValuePlaceholder;
			}

			&:read-only {
				@include formValueReadonly;
				@include cursorDisabled;

				user-select: none;
			}
		}
	}

	&__error-message {
		@include formValidation;

		display: none;
		padding: 0 ($spacing-s * 2);
	}
}
