@import 'inventory.scss';

.button-ghost {
	display: inline-flex;
	flex-direction: row;
	justify-content: center;

	.button-ghost__button {
		width: auto;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		outline: none;
		padding: ($spacing-xs * 3) ($spacing-s * 3);
		border-radius: 24px;
		background-color: transparent;
		border: none;

		.icon {
			color: $primary-50;
			width: 24px;
			height: 24px;
		}

		.button-ghost__text {
			@include buttonLabelDefault;
			padding: 0 (($spacing-xs * 3) - 2) 0 (($spacing-xs * 3) - 2);
		}

		&:hover:not(:disabled) {
			background-color: $primary-10;

			.button-ghost__text {
				@include buttonLabel;
			}

			.icon {
				color: $primary-100;
			}
		}

		&:active {
			height: 44px;
			padding: (($spacing-xs * 3) - 2) (($spacing-s * 3) - 2);
			margin: ($spacing-xs - 2);
		}

		&:disabled {
			@include cursorDisabled;

			.button-ghost__text {
				@include buttonLabelDisabled;
			}

			.icon {
				color: $primary-30;
			}
		}
	}
}
