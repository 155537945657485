@import 'inventory.scss';

.button-secondary {
	align-items: center;
	background: transparent;
	border: none;
	display: inline-flex;
	cursor: pointer;
	height: 48px;
	margin: 0;
	padding: 0;
	z-index: 100;

	&:hover {
		.button-secondary__button {
			border: solid 2px $primary-110;
			padding: 0 (($spacing-s * 3) - 1);
		}

		.button-secondary__text {
			@include buttonLabelHover;
		}

		&:active {
			padding: ($spacing-xs - 3);
		}
	}

	&:active {
		padding: 2px;

		.button-secondary__button {
			height: 44px;
			padding: 0 (($spacing-s * 3) - 2);
		}
	}

	&:disabled {
		@include cursorDisabled;

		.button-secondary__button {
			border: solid 1px $primary-30;
		}

		.button-secondary__text {
			@include buttonLabelDisabled;
		}

		.icon {
			color: $primary-30;
		}
	}

	&.status-attention {
		.button-secondary__button {
			border-color: $accent-error-100;
		}

		.button-secondary__text {
			color: $accent-error-100;
		}

		.icon {
			color: $accent-error-100;
		}

		&:hover {
			.button-secondary__button {
				border-color: $accent-error-110;
			}

			.button-secondary__text {
				color: $accent-error-110;
			}

			.icon {
				color: $accent-error-110;
			}
		}

		&:disabled {
			background-color: transparent;

			.button-secondary__button {
				border-color: $accent-error-20;
			}

			.button-secondary__text {
				color: $accent-error-20;
			}

			.icon {
				color: $accent-error-20;
			}
		}
	}

	&.height-small {
		height: 28px;

		.button-secondary__button {
			padding: 0 (($spacing-xs * 3) - 1);
		}

		&:hover {
			.button-secondary__button {
				padding: 0 (($spacing-xs * 3) - 2);
			}

			&:active {
				padding: ($spacing-xs - 2);
			}
		}

		&:active {
			padding: ($spacing-xs - 1);

			.button-secondary__button {
				height: 24px;
				padding: 0 $spacing-s;
			}
		}
	}

	.button-secondary__button {
		align-items: center;
		background-color: transparent;
		border: solid 1px $primary-100;
		border-radius: 24px;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		height: 100%;
		outline: none;
		padding: 0 ($spacing-s * 3);

		.icon {
			margin-right: $spacing-s;
		}
	}

	.button-secondary__text {
		@include buttonLabel;

		user-select: none;
	}
}
