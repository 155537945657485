@import 'inventory.scss';

.navigation-secondary {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: $spacing-m 0;

	@include mediaQuery($breakpoint-sm, down) {
		padding: ($spacing-s * 2) 0;
	}
}
