@import 'inventory.scss';

.card-generator-list-item-header {
	align-items: center;
	background-color: transparent;
	display: flex;
	flex-grow: 1;

	&__content {
		display: flex;
		flex-direction: column;
		background-color: transparent;
		flex-grow: 1;

		.tag {
			flex-shrink: 0;
			margin: $spacing-xs $spacing-s 0 ($spacing-s * 2);
		}
	}

	&__title-wrapper {
		align-items: flex-start;
		display: flex;
	}

	&__title {
		@include headingLevel2;

		hyphens: auto;
		margin: 0;
		padding: 0;
	}

	&__subtitle {
		@include captionSmall;

		margin: 0;
		padding: 0;
	}

	.illustration {
		flex-shrink: 0;
		margin-right: ($spacing-s * 2);

		@include mediaQuery($breakpoint-sm, down) {
			margin-right: $spacing-s;
		}
	}
}
