@import 'inventory.scss';

.site-title {
	background-color: $background-white;
	padding: ($spacing-s * 3) ($spacing-s * 2);
	border-radius: 8px;

	&--is-transparent {
		background-color: transparent;
		padding: ($spacing-s * 3) 0 ($spacing-s * 3) 0;
	}

	.site-title__header {
		display: flex;
		flex-direction: row;
		justify-items: baseline;
		justify-content: space-between;

		.site-title__title {
			@include headingLevel1;

			margin: $spacing-s (($spacing-m * 3) - (($spacing-s * 2) - 3)) $spacing-s 0;
		}
	}

	.button-toggle-set {
		margin-top: ($spacing-s * 3);
	}
}
