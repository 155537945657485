@import 'src/presentation/sass/inventory.scss';

.card-report-sequence-list {
	background-color: $background-white;
	border-radius: $spacing-s;
	box-shadow: 0 ($spacing-s * 1.5) ($spacing-s * 2.5) 0 transparentize($primary-100, 0.9);
	display: flex;
	flex-direction: column;
	position: relative;
	padding: ($spacing-s * 2) ($spacing-s * 2) ($spacing-s * 2) ($spacing-s * 4);
	width: 100%;

	&--is-archived {
		background-color: $primary-5;
	}

	&__content {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;

		.icon {
			margin-left: auto;
		}

		&__input {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 8px;

			&__label {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 8px;
			}
		}
	}

	.card-item-state {
		margin-top: $spacing-s * 3;
	}
}
