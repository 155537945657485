@import 'inventory.scss';

.card-item-controls {
	align-items: center;
	background-color: $white;
	display: flex;
	margin-left: ($spacing-xs * 7);
	outline: 0;

	.icon {
		cursor: pointer;
		padding: 0 ($spacing-xs * 3);

		&:first-of-type {
			padding-left: 0;
		}

		&:last-of-type {
			padding-right: 0;
		}

		&:hover {
			color: $primary-100;
		}

		&.control-icon--disabled {
			@include cursorDisabled;

			color: $primary-30;
		}
	}
}
