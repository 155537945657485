@import 'inventory.scss';

.error-page-layout {
	display: flex;
	flex-direction: column;
	gap: 40px;
	justify-content: center;
	align-items: center;
	margin-top: 120px;

}
