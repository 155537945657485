@import '../../../sass/inventory';

.modal-notification {
	display: block;

	&__illustration {
		display: block;
		margin-bottom: $spacing-m;
		text-align: center;

		img {
			display: inline-block;
			width: auto;
			max-width: 90vw;
			max-height: 128px;
		}
	}

	&__caption {
		@include headingLevel1;

		display: block;
		margin-bottom: $spacing-s;
		text-align: center;
		white-space: break-spaces;
	}

	&__message {
		@include paragraphCentered;

		display: block;
		margin-bottom: $spacing-m;
	}

}
