@import 'inventory.scss';

.button-toggle {
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	width: auto;

	.button-toggle__button {
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		cursor: pointer;
		outline: none;
		padding: $spacing-xs * 3;
		border-radius: 2px;
		background-color: $white;
		border: none;

		@include mediaQuery($breakpoint-sm, down) {
			height: 32px;
			padding: $spacing-xs $spacing-s $spacing-xs $spacing-xs;
		}

		.icon {
			color: $primary-100;
			width: 24px;
			height: 24px;
			margin: 0 $spacing-xs 0 0;

			@include mediaQuery($breakpoint-sm, down) {
				width: 20px;
				height: 20px;
			}
		}

		.button-toggle__text {
			@include buttonLabel;

			padding: 0 0 0 $spacing-s;
		}

		&.button-toggle__button--active {
			background-color: $primary-7;

			.icon {
				color: $primary-100;
			}

			.button-toggle__text {
				color: $primary-100;
			}
		}

		&:hover:not(:disabled) {
			background-color: $primary-7;

			.icon {
				color: $primary-110;
			}

			.button-toggle__text {
				color: $primary-110;
			}
		}

		&:active {
			height: 36px;
			padding: $spacing-s;
			margin: $spacing-xs;

			.icon {
				color: $primary-110;
			}

			.button-toggle__text {
				color: $primary-110;
			}
		}
	}
}
