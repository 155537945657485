@import 'inventory.scss';

.notification-bar {
	display: flex;
	position: relative;
	border-radius: 8px;
	overflow: hidden;

	&__dismiss {
		position: absolute;
		padding: ($spacing-xs * 3);
		top: ($spacing-s * 2);
		right: ($spacing-s * 2);
		cursor: pointer;
	}

	&__icon {
		flex-grow: 0;
		flex-shrink: 0;

		box-sizing: border-box;
		width: 80px;
		min-height: 80px;
		padding: ($spacing-xs * 7);
	}

	&__message {
		@include notificationMessage;

		flex-grow: 1;
		flex-shrink: 1;

		display: flex;
		flex-flow: column;
		justify-content: center;

		margin: 0;
		padding: ($spacing-s * 2);

		text-align: left;

		span {
			display: block;
		}
	}

	&--level-success {
		background-color: $accent-success-20;

		.notification-bar__dismiss {
			.icon {
				color: $accent-success-100;
			}
		}

		.notification-bar__icon {
			background-color: $accent-success-100;

			.icon {
				color: $accent-success-20;
			}
		}

		.notification-bar__message {
			color: $accent-success-110;
		}
	}

	&--level-notification {
		background-color: $primary-10;

		.notification-bar__dismiss {
			.icon {
				color: $primary-100;
			}
		}

		.notification-bar__icon {
			background-color: $primary-100;

			.icon {
				color: $primary-10;
			}
		}

		.notification-bar__message {
			color: $primary-110;
		}
	}

	&--level-info {
		background-color: transparent;

		.notification-bar__dismiss {
			.icon {
				color: $text-50;
			}
		}

		.notification-bar__icon {
			background-color: transparent;
			width: 24px;
			padding-left: 0;
			padding-right: 0;

			.icon {
				color: $text-100;
			}
		}

		.notification-bar__message {
			color: $text-100;
		}
	}

	&--level-warning {
		background-color: $accent-warning-20;

		.notification-bar__dismiss {
			.icon {
				color: $accent-warning-100;
			}
		}

		.notification-bar__icon {
			background-color: $accent-warning-100;

			.icon {
				color: $accent-warning-20;
			}
		}

		.notification-bar__message {
			color: $accent-warning-110;
		}
	}

	&--level-error {
		background-color: $accent-error-20;

		.notification-bar__dismiss {
			.icon {
				color: $accent-error-100;
			}
		}

		.notification-bar__icon {
			background-color: $accent-error-100;

			.icon {
				color: $accent-error-20;
			}
		}

		.notification-bar__message {
			color: $accent-error-110;
		}
	}

}
