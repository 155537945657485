@import 'inventory.scss';

.card-item-container-header {
	align-items: center;
	background-color: transparent;
	display: flex;
	flex-grow: 1;

	@include mediaQuery($breakpoint-sm, down) {
		max-width: 60%;
	}

	&__content {
		.tag {
			flex-shrink: 0;
			margin: $spacing-xs $spacing-s 0 ($spacing-s * 2);
		}
	}

	&__title-wrapper {
		align-items: flex-start;
		display: flex;
	}

	&__title {
		@include headingLevel1;

		hyphens: auto;
		margin: 0;
		padding: 0;

		@include mediaQuery($breakpoint-sm, down) {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__subtitle {
		@include captionRegular;

		margin: 0;
		padding: 0;
	}

	.illustration {
		flex-shrink: 0;
		margin-right: ($spacing-s * 2);

		@include mediaQuery($breakpoint-sm, down) {
			margin-right: $spacing-s;
		}
	}

	.tag--info {
		flex-shrink: 0;
		margin-left: auto;
	}

	&.no-illustration {
		padding-left: ($spacing-xs * 3);
	}
}
