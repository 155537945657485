@import 'inventory.scss';

.sequence-table-head {
	&__row {
		&__cell {
			position: relative;
			padding: $spacing-xs ($spacing-xs * 3) $spacing-s;
			border: none;
			white-space: nowrap;

			&__value {
				display: block;
			}

			&--major {
				@include captionBold;
				background-color: $primary-5;
			}

			&--minor {
				@include captionRegular;
				padding-bottom: ($spacing-xs * 3);
				background-color: $white;

				&::after {
					display: block;
					position: absolute;
					content: "";
					background-color: $primary-5;
					width: 100%;
					height: 8px;
					left: 0;
					bottom: 0;
				}
			}

			&--align-right {
				padding-right: ($spacing-s * 3);
				text-align: right;
			}
		}
	}
}
