@import 'inventory.scss';

.main-layout {
	display: flex;
	flex-direction: column;
	align-content: stretch;

	position: relative;
	min-height: 100vh;
}
