@import 'inventory.scss';

.card-report-sequence-item-header {
	background-color: transparent;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-grow: 1;

	@include mediaQuery($breakpoint-sm, down) {
		max-width: 60%;
	}

	&__content {
		display: flex;
		flex-direction: column;

		&__title {
			@include headingLevel2;

			hyphens: auto;
			margin: 0;
			padding: 0;

			@include mediaQuery($breakpoint-sm, down) {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&__subtitle {
			@include captionSmall;

			margin: 0;
			padding: 0;
		}
	}

	.tag {
		flex-shrink: 0;
		margin: 0 $spacing-s 0 ($spacing-s * 2);

		&--info {
			align-self: flex-start;
			flex-shrink: 0;
			margin-left: auto;
		}
	}
}
