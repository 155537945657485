@import 'inventory.scss';

.select {
	@include formValueDefault;

	max-height: 48px;
	min-width: 130px;
	position: relative;
	z-index: $zIndex-1;

	&__wrapper {
		outline: 0;

		&:hover {
			.select__title {
				border-color: $primary-70;

				.icon {
					color: $text-100;
				}
			}
		}

		&:focus {
			.select__title {
				@include formValueDefault;

				border: solid 2px $primary-100;
				padding: (($spacing-xs * 3)) (($spacing-s * 2));

				.icon {
					color: $text-100;
					flex-shrink: 0;
				}
			}
		}
	}

	&__title {
		@include formValuePlaceholder;

		align-items: center;
		background-color: $background-light;
		border-radius: 8px;
		border: solid 1px $primary-30;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		outline: 0;
		padding: ($spacing-xs * 3) ($spacing-s * 2);
		user-select: none;
		white-space: nowrap;
		width: 100%;

		&.select__title--hidden {
			display: none;
		}

		p {
			margin: 0;
			overflow: hidden;
			padding: 0;
			text-overflow: ellipsis;
			color: $text-100;
		}

		.icon {
			color: $text-50;
		}

		.icon--close {
			display: none;
		}
	}

	&__options {
		height: 0;
		list-style-type: none;
		margin: 0;
		overflow: hidden;
		padding: 0;

		.select__option {
			align-items: center;
			background-color: $primary-7;
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			padding: (($spacing-xs * 3) - 1) ($spacing-s * 2);

			&:hover {
				background-color: $primary-10;

				.icon {
					opacity: 1;
				}
			}

			&:active {
				background-color: $primary-10;
			}

			&:first-of-type {
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
			}

			&:last-of-type {
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
			}

			&__label {
				margin: 0 ($spacing-xs * 7) 0 0;
				padding: 0;
				pointer-events: none;
			}

			.icon {
				opacity: 0;
			}
		}
	}

	&__error-message {
		@include formValidation;

		display: none;
		padding: 0 ($spacing-s * 2);
	}

	&.select--disabled {
		@include cursorDisabled;

		user-select: none;

		.select__wrapper {
			.select__title {
				@include formValuePlaceholder;

				border-color: $primary-10;

				.icon {
					color: $text-50;
				}
			}
		}
	}

	&.select--open {

		z-index: $zIndex-2;

		ul {
			height: initial;
			margin: $spacing-s 0 0 0;
		}

		.select__title {
			.icon--close {
				display: block;
			}

			.icon--open {
				display: none;
			}
		}
	}

	&.select--open-to-top {
		.select__options {
			transform: translateY(calc(-100% - 48px - (2 * #{$spacing-s})));
		}
	}

	&.select--error {
		.select__title {
			@include formValueNegative;

			border-color: $accent-error-100;

			&:hover {
				@include formValueNegative;

				border-color: $accent-error-100;
			}

			.icon {
				color: $accent-error-110;
			}
		}

		.select__wrapper {
			&:hover,
			&:focus {
				.select__title {
					@include formValueNegative;

					border-color: $accent-error-100;

					.icon {
						color: $accent-error-110;
					}
				}
			}
		}

		.select__error-message {
			display: block;
		}
	}

	&.select--readonly {
		@include cursorDisabled;

		user-select: none;

		.select__title {
			@include formValueReadonly;

			background-color: $primary-5;
			border-color: transparent;
		}
	}

	&.select--summary {
		@include cursorDisabled;

		user-select: none;

		.select__title {
			@include formValueLabel;

			background-color: $primary-5;
			border-color: transparent;

			.icon {
				display: none;
			}
		}
	}
}
