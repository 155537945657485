@import 'inventory.scss';

.topbar-layout-section {
	background-color: $background-light;
	flex-shrink: 0;
	flex-grow: 0;
	left: 0;
	padding: 0 ($spacing-s * 3);
	position: sticky;
	top: 0;
	z-index: $zIndex-101;
}

.topbar-layout-section--fluid {
	position: static;
}
