@import 'inventory.scss';

.loading-spinner {
	left: 50%;
	top: 50%;
	position: absolute;
	transform: translate(-50%, -50%);

	&__orbit-wrapper {
		border-radius: 50%;
		height: 55px;
		perspective: 800px;
		width: 55px;

		* {
			box-sizing: border-box;
		}

		.loading-spinner__orbit {
			border-radius: 50%;
			box-sizing: border-box;
			height: 100%;
			position: absolute;
			width: 100%;

			&:nth-child(1) {
				animation: orbit-spinner-orbit-one-animation 1200ms linear infinite;
				border-bottom: 3px solid $primary-100;
				left: 0;
				top: 0;
			}

			&:nth-child(2) {
				animation: orbit-spinner-orbit-two-animation 1200ms linear infinite;
				border-right: 3px solid $primary-100;
				right: 0;
				top: 0;
			}

			&:nth-child(3) {
				animation: orbit-spinner-orbit-three-animation 1200ms linear infinite;
				border-top: 3px solid $primary-100;
				right: 0;
				bottom: 0;
			}
		}
	}
}

@keyframes orbit-spinner-orbit-one-animation {
	0% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}

	100% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@keyframes orbit-spinner-orbit-two-animation {
	0% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}

	100% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@keyframes orbit-spinner-orbit-three-animation {
	0% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}

	100% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}
