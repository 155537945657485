@import 'inventory.scss';

.expandable {
	display: flex;
	flex-direction: column;
	align-content: stretch;

	&.firstElement {
		margin-top: $spacing-s * 5;
	}

	&__header {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		text-align: left;
		border-radius: 8px;
		background-color: $background-white;
		overflow: hidden;

		&__caption {
			flex-grow: 1;
			flex-shrink: 1;

			display: flex;
			align-items: center;
			padding: ($spacing-s * 3);
			color: $primary-100;
			cursor: pointer;

			@include mediaQuery($breakpoint-sm, down) {
				padding: ($spacing-s * 2) $spacing-s ($spacing-s * 2) ($spacing-s * 2);
			}

			&:hover {
				color: $primary-110;
			}

			&__icon {
				margin-right: ($spacing-xs * 5);

				@include mediaQuery($breakpoint-sm, down) {
					margin-right: $spacing-xs;
				}
			}

			&__text {
				@include headingLevel1;
				color: currentColor;
			}

		}

		&__actions {
			align-items: center;
			display: flex;
			padding-left: $spacing-s;

			@include mediaQuery($breakpoint-sm, down) {
				margin: $spacing-s ($spacing-s * 2) $spacing-s auto;

				.badge {
					margin-right: 0;
				}

				.button-toggle-set {
					margin-right: 0;
				}
			}

			> * {
				margin: 0 ($spacing-s * 2);

				@include mediaQuery($breakpoint-sm, down) {
					margin: 0 $spacing-s;
				}
			}
		}
	}

	&__body {
		height: 0;
		overflow: hidden;
	}

	&--expanded {
		margin-bottom: ($spacing-m * 2);

		.expandable__header {
			margin-bottom: ($spacing-s * 2);

			&__caption {
				&__icon {
					transform: rotate(180deg);
				}
			}
		}

		.expandable__body {
			height: auto;
			overflow: visible;
		}
	}

	&-mode--slim {
		.expandable__header {
			margin-bottom: 0;

			&__caption {
				padding: 8px;

				&__icon {
					transform: rotate(0deg);
				}

				&__text {
					font-size: 18px;
				}
			}
		}

		.expandable__body {
			height: 0;
			overflow: hidden;
		}

		&--expanded {
			.expandable__header {
				&__caption {
					&__icon {
						transform: rotate(180deg);
					}
				}
			}

			.expandable__body {
				height: auto;
				overflow: visible;
				margin-bottom: $spacing-s;
			}
		}
	}
}
