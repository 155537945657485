@import 'src/presentation/sass/inventory';

.button-user-feedback-layout {
	display: flex;
	flex-direction: column;
	gap: $spacing-s;

	.report-view-user-feedback {
		&--positive {
			@include tableItemPositive;
		}

		&--negative {
			@include tableItemNegative;
		}
	}
}
