@import 'src/presentation/sass/inventory.scss';

.external-waste-management-report-cyclotron-product-detail {
	display: flex;
	flex-direction: column;
	gap: $spacing-s * 2;

	&__information {
		display: flex;
		flex-direction: column;

		&__attributes {
			display: flex;
			flex-direction: column;

			.attribute {
				display: flex;
				flex-direction: row;

				strong {
					@include captionBold;

				}

				p {
					@include captionRegular;

					display: inline-block;
					hyphens: auto;
					margin: 0;
					padding: 0;
				}
			}
		}

		&__items {
			display: flex;
			flex-direction: column;
			// margin: $spacing-s 0 $spacing-s 0;

			p {
				@include captionRegular;

				display: inline-block;
				hyphens: auto;
				margin: 0;
				padding: 0;
			}
		}
	}
}
