@import 'colors';

/*** FONT SIZE DEFINITIONS ***/
$font-size12: 12px;
$font-size14: 14px;
$font-size16: 16px;
$font-size18: 18px;
$font-size24: 24px;
$font-size32: 32px;
$font-size42: 42px;
$font-size64: 64px;

/*** FONT WEIGHT DEFINITIONS ***/
%nunito {
	font-family: 'Nunito', sans-serif;
	font-stretch: normal;
	font-style: normal;
}

%nunitoRegular {
	@extend %nunito;
	font-weight: 400;
}

%nunitoRegular14 {
	@extend %nunitoRegular;
	font-size: $font-size14;
}

%nunitoRegular16 {
	@extend %nunitoRegular;
	font-size: $font-size16;
}

%nunitoSemiBold {
	@extend %nunito;
	font-weight: 600;
}

%nunitoBold {
	@extend %nunito;
	font-weight: 700;
}

/*** LETTER SPACING DEFINITIONS ***/
$letter-spacing03: 0.3px;

/*** LINE HEIGHT DEFINITIONS ***/
$line-height20: 20px;
$line-height24: 24px;
$line-height48: 48px;

/***** TEXT DEFINITIONS *****/

/*** GENERAL ***/
%paragraph {
	@extend %nunitoRegular16;
	color: $text-100;
	line-height: $line-height24;
}

@mixin paragraphLeft {
	@extend %paragraph;
	text-align: left;
}

@mixin paragraphLeft--adaptive {
	@extend %paragraph;
	text-align: left;

	@include mediaQuery($breakpoint-sm, down) {
		font-size: $font-size14;
	}
}

@mixin paragraphRight {
	@extend %paragraph;
	text-align: right;
}

@mixin paragraphCentered {
	@extend %paragraph;
	text-align: center;
}

/*** BREADCRUMBS ***/
%breadcrumbs {
	@extend %nunitoRegular16;
	line-height: $line-height24;
}

@mixin breadcrumbs {
	@extend %breadcrumbs;
	color: $text-100;
}

@mixin breadcrumbsDefault {
	@extend %breadcrumbs;
	color: $text-50;
}

/*** BUTTON LABEL ***/
%buttonText {
	@extend %nunitoRegular16;
	line-height: $line-height24;
}

@mixin buttonLabel {
	@extend %buttonText;
	color: $primary-100;
}

@mixin buttonLabelHover {
	@extend %buttonText;
	color: $primary-110;
}

@mixin buttonLabelAttention {
	@extend %buttonText;
	color: $accent-error-100;
}

@mixin buttonLabelDefault {
	@extend %buttonText;
	color: $primary-50;
}

@mixin buttonLabelDisabled {
	@extend %buttonText;
	color: $primary-30;
}

@mixin buttonLabelLight {
	@extend %buttonText;
	color: $white;
}

/*** CAPTION ***/
%caption {
	color: $text-50;
	font-size: $font-size14;
	line-height: $line-height24;
	text-align: left;
}

@mixin captionBold {
	@extend %nunitoBold;
	@extend %caption;
}

@mixin captionSemiBold {
	@extend %nunitoSemiBold;
	@extend %caption;
}

@mixin captionRegular {
	@extend %nunitoRegular;
	@extend %caption;
}

@mixin captionSmall {
	@extend %nunitoRegular;
	@extend %caption;
	font-size: $font-size12;
}

@mixin captionSmallBold {
	@extend %nunitoBold;
	@extend %caption;
	font-size: $font-size12;
}

/*** SUMMARY WIDGET ***/
@mixin summaryLabel {
	@extend %nunitoBold;
	color: $text-100;
	font-size: $font-size16;
	line-height: $line-height24;
	text-align: left;
}

@mixin summaryValue {
	@extend %nunitoRegular;
	color: $text-100;
	font-size: $font-size16;
	line-height: $line-height24;
	text-align: left;
}

/*** FOOTER LINK ***/
@mixin footerLinkDefault {
	@extend %nunitoRegular;
	@extend %caption;

	text-decoration: none;
}

@mixin footerLinkHover {
	@extend %nunitoRegular;
	@extend %caption;
	color: $text-100;
}

/*** FORM ***/
%formValue {
	@extend %nunitoRegular16;

	line-height: $line-height24;
	text-align: left;
}

@mixin formValueDefault {
	@extend %formValue;
	color: $text-100;
}

@mixin formValueNegative {
	@extend %formValue;
	color: $accent-error-110;
}

@mixin formValuePlaceholder {
	@extend %formValue;
	color: $text-30;
}

@mixin formValuePositive {
	@extend %formValue;
	color: $accent-success-110;
}

@mixin formValueReadonly {
	@extend %formValue;
	color: $text-100;
}

@mixin formValueWarning {
	@extend %formValue;
	color: $accent-warning-110;
}

@mixin formValueLabel {
	@extend %nunitoBold;

	color: $text-100;
	font-size: $font-size16;
	line-height: $line-height24;
}

%formLabel {
	color: $text-100;
	font-size: $font-size16;
	line-height: $line-height24;
	text-align: left;
}

@mixin formLabel {
	@extend %formLabel;
	@extend %nunitoBold;
}

@mixin formLabelSummary {
	@extend %formLabel;
	@extend %nunitoRegular;
}

@mixin formValidation {
	@extend %nunitoRegular14;

	color: $accent-error-110;
	line-height: $line-height24;
}

/*** GREETING ***/
@mixin greeting {
	@extend %nunitoBold;
	color: $text-100;
	font-size: $font-size64;
	text-align: center;

	@include mediaQuery($breakpoint-sm, down) {
		font-size: $font-size42;
	}
}

/*** HEADING ***/
@mixin heading {
	@extend %nunitoBold;
	text-align: left;
}

@mixin headingLevel1 {
	@include heading;
	color: $text-100;
	margin: 0;
	font-size: $font-size24;

	@include mediaQuery($breakpoint-sm, down) {
		font-size: $font-size18;
	}
}

@mixin headingLevel1Primary100 {
	@include heading;
	color: $primary-100;
	margin: 0;
	font-size: $font-size24;
}

@mixin headingLevel2 {
	@include heading;
	color: $text-100;
	margin: 0;
	font-size: $font-size16;
}

/*** LINK ***/
@mixin linkText {
	@extend %nunitoRegular16;

	color: $text-50;

	@include mediaQuery($breakpoint-sm, down) {
		font-size: $font-size14;
	}
}

/*** MULTI SELECT ***/
%multiSelectText {
	@extend %nunitoRegular16;
}

// Used for selected items: active, default, disabled, hover
@mixin multiSelect {
	@extend %multiSelectText;
	color: $white;
}

// Used for active, disabled and hover
@mixin multiSelectDeselectActive {
	@extend %multiSelectText;
	color: $primary-70;
}

@mixin multiSelectDeselectDefault {
	@extend %multiSelectText;
	color: $primary-100;
}

/*** NAVIGATION ***/
%navigation {
	@extend %nunitoSemiBold;
	font-size: $font-size16;
	line-height: $line-height24;
	letter-spacing: $letter-spacing03;
}

@mixin navigationActive {
	@extend %navigation;
	color: $primary-100;
}

@mixin navigationDefault {
	@extend %navigation;
	color: $primary-50;
}

@mixin navigationDisabled {
	@extend %navigation;
	color: $primary-30;
}

// Used for hover and pressed
@mixin navigationHover {
	@extend %navigation;
	color: $primary-110;
}

// SUBTITLE ***/
%subtitle {
	@extend %nunitoBold;
	font-size: $font-size16;
	line-height: $line-height24;
}

@mixin subtitle {
	@extend %subtitle;

	color: $text-100;
}

@mixin subtitleAttention {
	@extend %subtitle;

	color: $accent-error-100;
}

/*** TABLE ITEM ***/
%tableItem {
	@extend %nunitoSemiBold;
	font-size: $font-size12;
}

@mixin tableItemNegative {
	@extend %tableItem;
	color: $accent-error-110;
}

@mixin tableItemNeutral {
	@extend %tableItem;
	color: $text-50;
}

@mixin tableItemPositive {
	@extend %tableItem;
	color: $accent-success-110;
}

@mixin tableItemWarning {
	@extend %tableItem;
	color: $accent-warning-110;
}

/*** TAG ***/
%tagText {
	@extend %nunitoRegular;
	font-size: $font-size12;
}

@mixin tagTextDark {
	@extend %tagText;
	color: $primary-100;
}

@mixin tagTextLight {
	@extend %tagText;
	color: $white;
}

/*** BADGE ***/
%badgeText {
	@extend %nunitoBold;

	font-size: $font-size12;
}

@mixin badgeTextDark {
	@extend %badgeText;

	color: $primary-100;
}

@mixin badgeTextLight {
	@extend %badgeText;

	color: $white;
}

/*** Sequences ***/
@mixin sequenceValueRecord {
	padding: 2px 16px;
	border-radius: 12px;
}

@mixin sequenceValueRecordValid {
	@include sequenceValueRecord;
	color: $accent-success-110;
	background-color: $accent-success-20;
}

@mixin sequenceValueRecordWarning {
	@include sequenceValueRecord;
	color: $accent-warning-110;
	background-color: $accent-warning-20;
}

@mixin sequenceValueRecordError {
	@include sequenceValueRecord;
	color: $accent-error-110;
	background-color: $accent-error-20;
}

/*** DASHBOARD-TEASER ***/
@mixin teaserCount {
	@extend %nunitoRegular;
	color: $secondary-100;
	font-size: $font-size32;
	line-height: 1em;
	text-align: center;

	@include mediaQuery($breakpoint-sm, down) {
		font-size: $font-size24;
	}
}

/*** Notifications ***/
@mixin notificationMessage {
	@extend %nunitoBold;
	font-size: 16px;
	line-height: 1.5em;
}
