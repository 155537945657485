@import 'inventory.scss';

.card-item-folder-controls {
	align-items: center;
	background-color: transparent;
	display: inline-flex;
	margin-left: ($spacing-s * 3);
	outline: 0;
	position: relative;

	.button-icon {
		+ .button-icon {
			margin-left: ($spacing-s * 3);
		}
	}

	&__select {
		bottom: 0;
		display: none;
		position: absolute;
		right: -($spacing-s * 2);
		transform: translateY(125%);
		white-space: nowrap;

		&.select--open {
			display: block;
		}
	}
}
