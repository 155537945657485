@import '../mixins/colors';

/*** GLOBAL STYLES ***/
html,
body {
	background-color: $background-light;
	font-family: 'Nunito', sans-serif, -apple-system, BlinkMacSystemFont;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	scroll-behavior: smooth;
}

html {
	height: -webkit-fill-available;
}

body {
	min-height: 100vh;
	min-height: -webkit-fill-available;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Nunito', monospace;
}

fieldset {
	border: none;
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-block-start: 0;
	padding-inline-start: 0;
	padding-inline-end: 0;
	padding-block-end: 0;
}
