@import 'src/presentation/sass/inventory.scss';

.generator-table-wrapper {
	position: relative;
	padding: $spacing-s;

	background-color: $background-light;
	overflow: hidden;

	&__pane {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	.generator-table {
		width: 100%;
		text-align: left;
		border-collapse: collapse;
		border-spacing: $spacing-s $spacing-xs;
	}
}
