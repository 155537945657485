@import 'src/presentation/sass/inventory';

.card-collection-layout {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: stretch;

	> * {
		margin-bottom: ($spacing-s * 2);

		&:last-child {
			margin-bottom: 0;
		}
	}

	.card-collection-layout__header {
		align-items: center;
		display: inline-flex;
		flex-wrap: wrap;
		margin-bottom: ($spacing-s * 3);
		padding: 0 ($spacing-s * 2);
	}
}
