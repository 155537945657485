@import 'inventory.scss';

.time-picker {
	align-items: center;
	background-color: $white;
	border-radius: 8px;
	border: solid 1px $primary-30;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	position: relative;
	width: 175px;

	&:hover:not(.disabled):not(.error):not(:focus):not(:focus-within) {
		border: solid 1px $primary-70;

		.icon {
			color: $text-100;
		}
	}

	&:focus:not(.disabled):not(.error),
	&:focus-within:not(.disabled):not(.error) {
		border: solid 2px $primary-100;

		input {
			padding: ($spacing-s + 1) (($spacing-s * 2) - 1);
		}

		.icon {
			color: $text-100;
			padding-right: (($spacing-s * 2) - 1);
		}
	}

	input {
		@include formValueDefault;

		background: none;
		border: 0;
		cursor: pointer;
		height: 26px;
		outline: 0;
		padding: ($spacing-s + 2) ($spacing-s * 2);
		width: 100%;

		&:read-only {
			@include formValueReadonly;
			@include cursorDisabled;

			user-select: none;
		}

		&::-webkit-inner-spin-button,
		&::-webkit-calendar-picker-indicator {
			cursor: pointer;
			opacity: 0;
			outline: 0;
			-webkit-appearance: none;
		}
	}

	&.time-picker--disabled {
		@include cursorDisabled;

		border-color: $primary-10;
		user-select: none;

		input {
			@include formValuePlaceholder;
		}

		.icon {
			color: $text-30;
		}
	}

	&.time-picker--error {
		background-color: $white;
		border-color: $accent-error-100;

		&:hover {
			border-color: $accent-error-100;
		}

		input {
			@include formValueNegative;
		}

		.icon {
			color: $accent-error-110;
		}
	}

	&.time-picker--readonly {
		@include cursorDisabled;

		background-color: $primary-5;
		border: 0;
		user-select: none;

		input {
			@include formValueReadonly;

			padding: ($spacing-s + 2) (($spacing-s * 2) + 1);
		}

		.icon {
			background-color: $primary-5;
			color: $text-50;
			padding-right: (($spacing-s * 2) + 1);
		}
	}

	.icon {
		background-color: $white;
		color: $text-50;
		margin-left: $spacing-xs * 3;
		pointer-events: none;
		position: absolute;
		padding-right: ($spacing-s * 2);
		right: 0;
	}
}
