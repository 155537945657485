@import 'inventory.scss';

.input-text {
	&:hover:not(.input-text--disabled):not(.input-text--error):not(.input-text--negative):not(.input-text--positive):not(.input-text--warn) {
		.input-text__wrapper {
			border: solid 1px $primary-70;

			.icon {
				color: $text-100;
			}
		}
	}

	&:focus:not(.input-text--disabled):not(.input-text--error):not(.input-text--negative):not(.input-text--positive):not(.input-text--warn),
	&:focus-within:not(.input-text--disabled):not(.input-text--error):not(.input-text--negative):not(.input-text--positive):not(.input-text--warn) {
		.input-text__wrapper {
			border: solid 2px $primary-100;
			padding: (($spacing-xs * 3) - 2) (($spacing-s * 2) - 1);

			.icon {
				color: $text-100;
			}
		}
	}

	&.input-text--disabled {
		.input-text__wrapper {
			@include cursorDisabled;

			background-color: $white;
			border-color: $primary-10;
			user-select: none;

			input,
			input::placeholder {
				@include formValuePlaceholder;
			}

			.icon {
				color: $text-30;
			}
		}
	}

	&.input-text--invalid {
		.input-text__wrapper {
			background-color: $white;
			border-color: $accent-error-100;

			&:hover {
				border-color: $accent-error-100;
			}

			input {
				@include formValueNegative;
			}

			.icon {
				color: $accent-error-110;
			}
		}

		.input-text__error-message {
			display: block;
		}
	}

	&.input-text--negative {
		@include cursorDisabled;

		user-select: none;

		.input-text__wrapper {
			background-color: $accent-error-20;
			border: 0;
			padding: ($spacing-xs * 3) ($spacing-s * 2);

			&:hover {
				border: 0;
			}

			input {
				@include formValueNegative;
			}

			.icon {
				color: $accent-error-110;
			}
		}
	}

	&.input-text--positive {
		@include cursorDisabled;

		user-select: none;

		.input-text__wrapper {
			background-color: $accent-success-20;
			border: 0;
			padding: ($spacing-xs * 3) ($spacing-s * 2);

			&:hover {
				border: 0;
			}

			input {
				@include formValuePositive;
			}

			.icon {
				color: $accent-success-110;
			}
		}
	}

	&.input-text--readonly {
		@include cursorDisabled;

		user-select: none;

		.input-text__wrapper {
			background-color: $primary-5;
			border: 0;
			padding: ($spacing-xs * 3) ($spacing-s * 2);

			input {
				@include formValueReadonly;
			}

			.icon {
				background-color: $primary-5;
				color: $text-50;
			}
		}
	}

	&.input-text--summary {
		@include cursorDisabled;

		user-select: none;

		.input-text__wrapper {
			background-color: $primary-5;
			border: 0;
			padding: ($spacing-xs * 3) ($spacing-s * 2);

			input {
				@include formValueLabel;
			}
		}
	}

	&.input-text--warn {
		@include cursorDisabled;

		user-select: none;

		.input-text__wrapper {
			background-color: $accent-warning-20;
			border: 0;
			padding: ($spacing-xs * 3) ($spacing-s * 2);

			&:hover {
				border: 0;
			}

			input {
				@include formValueWarning;
			}

			.icon {
				color: $accent-warning-110;
			}
		}
	}

	&__input {
		@include formValueDefault;

		background: none;
		border: 0;
		box-sizing: border-box;
		margin: 0;
		outline: 0;
		padding: 0;
		text-overflow: ellipsis;
		width: 100%;

		&::placeholder {
			@include formValuePlaceholder;

			text-overflow: ellipsis;
		}

		&:read-only {
			@include formValueReadonly;
			@include cursorDisabled;

			user-select: none;
		}

		&--cropped {
			width: calc(100% - 24px - #{$spacing-xs * 3});
		}
	}

	&__wrapper {
		border-radius: 8px;
		border: solid 1px $primary-30;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		min-width: 113px;
		padding: (($spacing-xs * 3) - 1) ($spacing-s * 2);

		.icon {
			color: $text-50;
			margin-left: $spacing-xs * 3;
		}
	}

	&__error-message {
		@include formValidation;

		display: none;
		padding: 0 ($spacing-s * 2);
	}
}
