@import 'inventory.scss';

.radiobutton {
	display: inline-flex;
	align-items: center;
	background-color: $white;

	&__label {
		@include formValueDefault;

		padding-left: $spacing-s;
	}

	&.radiobutton--summary {
		background-color: $primary-5;
		padding: 0 0 ($spacing-xs) ($spacing-xs * 2);
		pointer-events: none;

		&:last-child {
			padding-bottom: $spacing-xs * 3;
		}

		.radiobutton__input {
			display: none;
		}

		.radiobutton__label {
			@include summaryLabel;
		}
	}
}
