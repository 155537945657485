@import 'inventory.scss';

.expandable-slim {
	display: flex;
	flex-direction: column;
	align-content: stretch;
	margin-bottom: 16px;

	&.firstElement {
		margin-top: $spacing-s * 5;
	}

	&__header {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		text-align: left;
		border-radius: 8px;
		background-color: $background-white;
		overflow: hidden;

		&__caption {
			flex-grow: 1;
			flex-shrink: 1;

			display: flex;
			flex-direction: row;
			align-content: space-between;
			align-items: center;
			padding: ($spacing-s * 2);
			color: $primary-100;
			cursor: pointer;

			@include mediaQuery($breakpoint-sm, down) {
				padding: ($spacing-s * 2) $spacing-s ($spacing-s * 2) ($spacing-s * 2);
			}

			&:hover {
				color: $primary-110;
			}

			&__icon {
				margin-left: auto;

				@include mediaQuery($breakpoint-sm, down) {
					margin-right: $spacing-xs;
				}
			}

			&__text-wrapper {
				margin-left: $spacing-s * 2;
				margin-right: auto;

				&__title {
					@include headingLevel1;
					color: currentColor;
				}
			}
		}
	}

	&__body {
		height: 0;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	&--expanded {
		margin-bottom: ($spacing-m * 2);

		.expandable-slim__header {
			margin-bottom: ($spacing-s * 2);

			&__caption {
				&__icon {
					transform: rotate(180deg);
				}
			}
		}

		.expandable-slim__body {
			height: auto;
			overflow: visible;
		}
	}

	&-mode--slim {
		.expandable-slim__header {
			margin-bottom: 0;
			background-color: unset;

			&__caption {
				padding: 16px 8px;

				&__icon {
					transform: rotate(0deg);
					margin-left: 0;
				}

				&__text-wrapper {
					&__title {
						font-size: 16px;
					}
				}
			}
		}

		.expandable-slim__body {
			height: 0;
			overflow: hidden;
		}

		&--expanded {
			.expandable-slim__header {
				&__caption {
					&__icon {
						transform: rotate(180deg);
					}
				}
			}

			.expandable-slim__body {
				height: auto;
				overflow: visible;
				margin-bottom: $spacing-s;
			}
		}
	}
}
