@import 'inventory.scss';

.filter-chip-set {
	&__label {
		@include paragraphLeft;

		margin: 0;
		padding: 0;
		user-select: none;
	}

	&__chips {
		align-content: flex-start;
		display: flex;
		flex-wrap: wrap;

		.chip {
			margin: $spacing-s 0;

			&:active {
				margin: ($spacing-s + 2) ($spacing-xs - 2) $spacing-s ($spacing-xs - 2);
			}

			&:not(:first-of-type) {
				margin-left: ($spacing-s * 2);

				&:active {
					margin-left: (($spacing-s * 2) + 2);
				}
			}

			&:only-child {
				&:active {
					padding-bottom: ($spacing-xs - 2);
				}
			}
		}
	}

	+ .filter-chip-set {
		margin-left: $spacing-m;
	}
}
