@import 'inventory.scss';

.form-layout {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	align-content: stretch;
	min-width: 100%;

	> .form-layout-section {
		width: 100%;
		margin: 0;
		padding-bottom: $spacing-m;
		text-align: center;
		border: none;
		outline: none;

		&:last-child:not(.form-layout-section--align-bottom) {
			padding-bottom: 0;
		}

		&--align-bottom,
		&:last-child.form-layout-section--align-bottom {
			background-color: transparent;
			margin-top: $spacing-m;
			padding-bottom: 0;
		}
	}

	&--align-vertical {
		.form-layout-section {
			text-align: left;

			&:last-child:not(:only-child) {
				text-align: center;
			}
		}
	}

	&--align-horizontal {
		.label {
			display: flex;
			flex-direction: row;
			align-content: stretch;

			> * {
				flex-grow: 2;
				flex-shrink: 2;
				width: 200px;
			}

			@include mediaQuery($breakpoint-sm, down) {
				.input-file {
					width: auto;
				}
			}

			&.label--thin .label__labeltext,
			&__labeltext {
				flex-grow: 1;
				flex-shrink: 1;
				width: 100px;
				margin-right: 24px;
			}
		}
	}

	&--blue-background {
		padding: 0;

		> .form-layout-section:not(.form-layout-section--align-bottom) {
			background-color: $primary-5;
			padding: 0 ($spacing-s * 2);
			width: auto;

			+ .form-layout-section:not(.form-layout-section--align-bottom) {
				padding: $spacing-s ($spacing-s * 2);
			}
		}

		.icon {
			display: none;
		}
	}

	&__header {
		@include subtitle;

		align-items: center;
		display: flex;
		margin-bottom: ($spacing-s * 5);

		.icon {
			margin-right: $spacing-s;
		}
	}

}
