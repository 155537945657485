/*** COLORS ***/

$accent-success-110: #04744a;
$accent-success-100: #00d082;
$accent-success-20: #ccf6e6;
$accent-warning-110: #7f6c0a;
$accent-warning-100: #fad202;
$accent-warning-20: #fef6cc;
$accent-error-110: #9f0f01;
$accent-error-100: #ea1601;
$accent-error-20: #fbd0cc;

$background-white: #fff;
$background-light: #f0f4f7;

$primary-110: #0a3766;
$primary-100: #194c80;
$primary-70: #366699;
$primary-50: #5985b3;
$primary-30: #85a8cc;
$primary-10: #b8cee6;
$primary-7: #e6f0fa;
$primary-5: #f2f9ff;

$secondary-100: #3dedd6;

$text-100: #0e2c4d;
$text-50: #8394a6;
$text-30: #b6bfc9;

$white: #fff;

$debug: #f0f;
