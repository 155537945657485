@import 'inventory.scss';

.card-item-sequence-header {
	align-items: center;
	background-color: transparent;
	display: flex;
	flex-grow: 1;

	&__content {
		margin-right: ($spacing-s * 2);
	}

	&__title-wrapper {
		align-items: flex-start;
		display: flex;
	}

	&__title {
		@include headingLevel1;

		hyphens: auto;
		margin: 0;
		padding: 0;
	}

	&__subtitle {
		@include captionRegular;

		margin: 0;
		padding: 0;
	}

	.tag {
		flex-shrink: 0;
		margin-left: auto;
	}
}
