@import 'inventory.scss';

.forgot-password-link {
	margin-top: $spacing-m;
	margin-bottom: ($spacing-s * 6);
	text-align: right;

	a {
		@include linkText;
	}
}
