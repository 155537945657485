@import 'inventory.scss';

.card-numeric-batch {
	align-items: center;
	align-self: stretch;
	background-color: $white;
	border-radius: 8px;
	box-shadow: 0 6px 20px 0 rgba(23, 75, 129, 0.1);
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	padding: $spacing-s;

	&.card-numeric-batch--linked {
		max-width: initial;
		padding: 0;

		&:active {
			box-shadow: 0 3px 10px 0 rgba(23, 75, 129, 0.15);
		}
	}

	&__count {
		@include teaserCount;

		background-color: $primary-5;
		border-radius: 4px;
		height: 40px;
		margin-right: ($spacing-s * 2);
		min-width: 48px;
		overflow: hidden;
		padding-top: $spacing-s;
		text-align: center;

		@include mediaQuery($breakpoint-sm, down) {
			height: 24px;
			min-width: 32px;
			padding-top: $spacing-xs;
		}
	}

	&__label {
		@include paragraphLeft--adaptive;

		margin: 0;
		overflow: hidden;
		padding: 0;

		overflow-wrap: break-word;
		word-wrap: break-word;

		-webkit-hyphens: auto;
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		hyphens: auto;

		@include mediaQuery($breakpoint-sm, down) {
			width: calc(100% - 32px - #{$spacing-s * 2});
		}
	}

	a {
		align-items: center;
		display: inline-flex;
		justify-content: center;
		padding: ($spacing-s * 2);
		text-decoration: none;
		max-width: 328px;
	}
}
