/*** Cursor ***/

@mixin cursorDisabled {
	cursor: default;
	pointer-events: none;
}

/*** Media query ***/
@mixin mediaQuery($size, $direction: up) {
	@if $direction == up {
		@media screen and (min-width: $size) {
			@content;
		}
	}

	@else if $direction == down {
		@media screen and (max-width: $size) {
			@content;
		}
	}

	@else if $direction == only {
		@if $size == $breakpoint-sm {
			@media screen and (min-width: $size) and (max-width: $breakpoint-md) {
				@content;
			}
		}

		@else if $size == $breakpoint-md {
			@media screen and (min-width: $size) and (max-width: $breakpoint-l) {
				@content;
			}
		}

		@else if $size == $breakpoint-l {
			@media screen and (min-width: $size) and (max-width: $breakpoint-xl) {
				@content;
			}
		}
	}
}
