@import "inventory";

.output-widget {
	display: flex;
	flex-direction: column;
	list-style-type: none;
	margin: 0;
	padding: 0;

	li {
		margin-bottom: $spacing-xs;
	}

	strong {
		@include summaryLabel;

		margin-right: 16px;
	}

	p {
		@include summaryValue;

		display: inline-block;
		hyphens: auto;
		margin: 0;
		padding: 0;
	}

}
