@import 'inventory.scss';

.breadcrumbs {
	width: auto;
	text-align: left;

	a {
		@include breadcrumbsDefault;

		text-transform: capitalize;

		&:not(:last-of-type)::after {
			content: " / ";
			display: inline-block;
			white-space: pre;
		}

		&:last-of-type {
			@include breadcrumbs;
			@include cursorDisabled;

			text-decoration: none;
		}

		&:hover:not(:last-of-type) {
			@include breadcrumbs;
		}
	}
}
