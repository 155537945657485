@import 'inventory.scss';

.card-numeric-batch-collection {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	margin: auto;
	margin-bottom: 28px;
	position: relative;
	z-index: $zIndex-5;
	gap: $spacing-s;

	@include mediaQuery($breakpoint-m) {
		flex-direction: row;
		gap: $spacing-s * 2;

		.card-numeric-batch {
			flex: 1 1;
		}
	}
}
