@import 'inventory.scss';

.card-membership {
	background-color: $background-white;
	border-radius: 8px;
	box-shadow: 0 6px 20px 0 transparentize($primary-100, 0.9);
	display: flex;
	position: relative;

	&__content {
		align-items: center;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: ($spacing-s * 3) ($spacing-s * 2) ($spacing-s * 2) ($spacing-s * 2);
		width: 100%;

		.card-item-controls {
			@include mediaQuery($breakpoint-xl, down) {
				margin-left: auto;

			}
		}
	}

	.card-item-header {
		@include mediaQuery($breakpoint-xl, down) {
			max-width: 72.972972973%;

		}
	}
}
