@import 'inventory.scss';

.card-item-maintenance-log-attributes {
	background-color: $primary-5;
	border-radius: 4px;
	padding: ($spacing-s * 2);
	text-align: left;
	width: 100%;
	margin-top: $spacing-s * 3;

	strong {
		@include captionBold;

		display: inline-block;
	}

	p {
		@include captionRegular;

		display: inline-block;
		hyphens: auto;
		margin: 0;
		padding: 0;
	}

	.card-item-maintenance-log-attributes__grid {
		display: grid;
		grid-auto-rows: min-content;
		grid-template-columns: repeat(2, calc((100% - #{$spacing-s}) / 2));
		grid-template-rows: repeat(2, min-content);
		grid-column-gap: $spacing-s;
		grid-row-gap: $spacing-s;
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	.grid-item {
		display: inline-flex;

		&:nth-child(3n) {
			grid-column: span 2;
		}

	}
}
