@import 'inventory.scss';

.card-empty {
	align-items: center;
	background-color: $background-white;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	min-height: 120px;
	padding: ($spacing-s * 2);

	&__message {
		@include captionRegular;
	}
}
