@import 'inventory.scss';

.button-icon {
	display: inline-flex;
	flex-direction: row;
	justify-content: center;

	.button-icon__button {
		width: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		outline: none;

		.icon {
			pointer-events: none;
			width: 24px;
			height: 24px;
		}

		&.button-icon--round {
			border-radius: 24px;
			height: 48px;
		}

		&.button-icon--primary {
			background-color: $primary-100;
			border: none;

			.icon {
				color: $white;
			}

			&:hover:not(:disabled) {
				background-color: $primary-110;
				border: solid 2px $primary-110;
			}

			&:disabled {
				@include cursorDisabled;
				background-color: $primary-30;
				border: solid 2px $primary-30;
			}

			&:active:not(.button-icon--rectangle) {
				width: 44px;
				height: 44px;
				box-shadow: 0 1px 4px 0 $primary-50;
				margin: ($spacing-xs - 2);
			}

			&.button-icon--rectangle {
				min-height: 104px;
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;

				&:active {
					background-color: $primary-110;
				}
			}
		}

		&.button-icon--secondary {
			background-color: transparent;
			border: solid 1px $primary-100;

			.icon {
				color: $primary-100;
			}

			&:hover:not(:disabled) {
				border: solid 2px $primary-100;

				.icon {
					color: $primary-110;
				}
			}

			&:disabled {
				@include cursorDisabled;
				border: solid 1px $primary-30;

				.icon {
					color: $primary-30;
				}
			}

			&:active {
				width: 44px;
				height: 44px;
				margin: ($spacing-xs - 2);
			}
		}

		&.button-icon--ghost {
			background-color: transparent;
			border: none;

			.icon {
				color: $primary-50;
			}

			&:hover:not(:disabled):not(:active) {
				width: 40px;
				height: 40px;
				background-color: $primary-10;
				margin: $spacing-xs;

				.icon {
					color: $primary-100;
				}
			}

			&:disabled {
				@include cursorDisabled;

				.icon {
					color: $primary-30;
				}
			}

			&:active {
				width: 36px;
				height: 36px;
				background-color: $primary-10;
				margin: ($spacing-s - 2);

				.icon {
					color: $primary-100;
				}
			}

			&:focus-visible {
				border: 2px solid $primary-100; // TODO: There is no UX concept for focus-visible; this should be revisited by a designer
			}
		}
	}

	.badge {
		align-self: center;
		transform: translateX(-8px) translateY(-4px);
	}

}
