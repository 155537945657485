@import 'inventory.scss';

.user-profile {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-left: auto;
	position: relative;

	.user-profile__box-closed {
		width: auto;
		height: 48px;
		display: flex;
		flex-direction: row;
		background-color: transparent;

		.user-profile__texts {
			display: flex;
			flex-direction: column;
			outline: none;
			pointer-events: none;

			@include mediaQuery($breakpoint-sm, down) {
				pointer-events: all;
			}

			p {
				margin: 0;

				&.user {
					@include headingLevel2;
					text-align: right;
				}

				&.location {
					@include paragraphRight;
				}
			}
		}
	}

	.user-profile__box-opened {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		width: 383px;
		margin: ($spacing-s * 10) 0 ($spacing-s * 2) (($spacing-m * 3) - $spacing-xs);
		border-radius: 8px;
		box-shadow: 0 6px 20px 0 rgba(23, 75, 129, 0.1);
		background-color: $white;
		position: absolute;

		&--without-facility-selection {
			height: 48px;
		}

		div {
			padding: ($spacing-xs * 3) ($spacing-s * 2);
			margin: 0;
			cursor: pointer;
			text-align: left;
			width: calc(100% - #{$spacing-s * 4});

			&:hover {
				background-color: $primary-30;

				&:first-child {
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;
				}

				&:last-child {
					border-bottom-left-radius: 8px;
					border-bottom-right-radius: 8px;
				}
			}
		}

		.label-client,
		.label-location,
		.label-logout {
			@include buttonLabel;
		}

		.label-logout {
			color: $primary-50;
		}
	}
}
