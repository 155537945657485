.sequence-period-selection {
	display: flex;
	gap: 16px;

	margin: 24px 0;

	&__input {
		&__label {
			display: flex;
			align-items: center;
			gap: 16px;
		}

	}
}
