@import 'inventory.scss';

.chip {
	cursor: pointer;
	max-height: 32px;
	white-space: nowrap;
	outline: 0;

	&:not(.chip--active) {
		@include multiSelectDeselectDefault;
	}

	&:hover {
		.chip__content {
			@include mediaQuery($breakpoint-xl) {
				background-color: $primary-30;
			}
		}
	}

	&:active {
		margin: ($spacing-xs / 2);

		.chip__content {
			background-color: $primary-30;
			padding: ($spacing-xs - 2) (($spacing-xs * 3) + 2);
		}
	}

	&__content {
		@include cursorDisabled;

		background-color: $white;
		border-radius: 8px;
		display: block;
		padding: $spacing-xs ($spacing-s * 2);
		user-select: none;
	}

	&.chip--active {
		@include multiSelect;

		.chip__content {
			background-color: $primary-100;
		}

		&:hover {
			.chip__content {
				@include mediaQuery($breakpoint-xl) {
					background-color: $primary-100;
				}
			}
		}

		&:active {
			.chip__content {
				background-color: $primary-110;
			}
		}
	}
}
