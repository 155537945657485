@import 'inventory.scss';

.illustration {
	color: $primary-50;
	height: 72px;
	overflow: hidden;
	width: 72px;

	@include mediaQuery($breakpoint-sm, down) {
		height: 40px;
		width: 40px;
	}

	svg {
		height: 100%;
		width: 100%;
	}
}
