@import 'inventory.scss';

.card-cyclotron-product-list-item {
	background-color: $white;
	border-radius: 8px;
	box-shadow: 0 6px 20px 0 transparentize($primary-100, 0.9);
	display: flex;
	position: relative;

	&__content {
		align-items: center;
		cursor: pointer;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: (($spacing-s * 2) - 1) ($spacing-s * 2);
		width: 100%;
	}

	&.card-cyclotron-product-list-item--disabled {
		background-color: $primary-5;
		box-shadow: none;

		.card-cyclotron-product-list-item__content {
			@include cursorDisabled;

			user-select: none;
		}
	}

	&.card-cyclotron-product-list-item--completely-applied {
		background-color: $primary-5;
	}

	.card-cyclotron-product-list-item-header {
		margin-left: ($spacing-s * 2);

		@include mediaQuery($breakpoint-sm, down) {
			.card-cyclotron-product-list-item-header__content {
				margin-right: $spacing-s;

				.list-item-header__schedule-tag {
					flex-shrink: 0;
				}
			}
		}
	}

	.card-item-state {
		margin-top: $spacing-xs;
	}
}
