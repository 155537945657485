@import 'inventory.scss';

.icon {
	color: $primary-100;
	height: 24px;
	overflow: hidden;
	width: 24px;

	@include mediaQuery($breakpoint-sm, down) {
		height: 20px;
		width: 20px;
	}

	svg {
		height: 100%;
		pointer-events: none;
		width: 100%;
	}

	&.accent-error-20 {
		color: $accent-error-20;
	}

	&.accent-error-100 {
		color: $accent-error-100;
	}

	&.accent-success-110 {
		color: $accent-success-110;
	}

	&.primary-10 {
		color: $primary-10;
	}

	&.primary-30 {
		color: $primary-30;
	}

	&.primary-50 {
		color: $primary-50;
	}

	&.primary-100 {
		color: $primary-100;
	}

	&.primary-110 {
		color: $primary-110;
	}

	&.text-50 {
		color: $text-50;
	}

	&.text-100 {
		color: $text-100;
	}

	&.white {
		color: $white;
	}
}
