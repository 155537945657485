@import 'inventory.scss';

.button-primary {
	display: inline-flex;
	flex-direction: row;
	justify-content: center;

	.button-primary__button {
		width: auto;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		outline: none;
		padding: ($spacing-xs * 3) ($spacing-s * 3);
		border-radius: 24px;
		background-color: $primary-100;
		border: none;

		.icon {
			color: $white;
			width: 24px;
			height: 24px;
		}

		.button-primary__text {
			@include buttonLabelLight;
			padding: 0 (($spacing-xs * 3) - 2) 0 (($spacing-xs * 3) - 2);
		}

		&:hover:not(:disabled) {
			background-color: $primary-110;
		}

		&:active {
			height: 44px;
			padding: (($spacing-xs * 3) - 2) (($spacing-s * 3) - 2);
			margin: ($spacing-xs - 2);
		}

		&:disabled {
			@include cursorDisabled;
			background-color: $primary-30;
		}
	}
}
