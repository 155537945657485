@import "inventory";

.record-comment-modal {
	padding: $spacing-m;

	&__header {
		display: flex;
		gap: $spacing-xs;
		margin-bottom: 8px;
		padding-right: 40px;

		&__column {
			flex: 1 1;

			display: flex;
			gap: $spacing-s;
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		gap: $spacing-xs;
		margin-top: 16px;

		.record-comment-modal__value {
			@include paragraphLeft;
			white-space: pre-line;
		}
	}

	&__label {
		@include captionBold;
		white-space: nowrap;
	}

	&__value {
		@include captionSemiBold;
		line-height: $line-height24;

		&--record-acceptable {
			@include sequenceValueRecordValid;
			margin-right: ($spacing-xs * 3);
		}

		&--record-noticeable {
			@include sequenceValueRecordWarning;
			margin-right: ($spacing-xs * 3);
		}

		&--record-unacceptable {
			@include sequenceValueRecordError;
			margin-right: ($spacing-xs * 3);
		}

		&--soft-threshold {
			color: $accent-warning-110;
		}

		&--hard-threshold {
			color: $accent-error-110;
		}
	}

}
