@import 'inventory.scss';

html,
body {
	margin: 0;
	padding: 0;

	min-height: 100vh;
	//noinspection CssInvalidPropertyValue
	min-height: -webkit-fill-available;
	overscroll-behavior: none;
}

.root-layout {
	display: flex;
	min-height: 100vh;
	overflow: scroll;
}
