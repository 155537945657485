@import 'inventory.scss';

.date-time-picker {
	.date-picker {
		border-bottom: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		&:hover:not(.disabled):not(.error):not(:focus):not(:focus-within) {
			border-bottom: 0;

			+ .time-picker {
				border-top: solid 1px $primary-70;
			}
		}

		&:focus:not(.disabled):not(.error),
		&:focus-within:not(.disabled):not(.error) {
			+ .time-picker {
				border-top: 0;

				&:hover {
					border-top: 0;
				}
			}
		}
	}

	.time-picker {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}
