@import 'inventory.scss';

.logo-header {
	width: 260px;
	background-image: url('../../../../../assets/logo/logo-navigation-expand.webp');
	background-repeat: no-repeat;
	background-position: center;
	height: 48px;
	padding: (($spacing-m * 4) - ($spacing-s * 2)) 0;
	margin: 0 auto;
}
