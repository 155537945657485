@import 'inventory.scss';

.card-item-generator-dispatch-attributes {
	background-color: $primary-5;
	border-radius: 4px;
	padding: ($spacing-s * 2);
	text-align: left;
	width: 100%;
	margin-top: $spacing-s;

	strong {
		@include captionBold;

	}

	p {
		@include captionRegular;

		display: inline-block;
		hyphens: auto;
		margin: 0;
		padding: 0;
	}

	.card-item-generator-dispatch-attributes__attribute {
		display: grid;
		grid-auto-rows: min-content;
		grid-template-columns: repeat(2, calc((100% - #{$spacing-s}) / 2));
		grid-template-rows: repeat(2, min-content);
		grid-column-gap: $spacing-s;
		grid-row-gap: $spacing-s;
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	.attribute {
		display: inline-flex;
	}
}
