@import 'src/presentation/sass/inventory.scss';

.external-waste-management-report-generator-detail {
	&__header {
		@include headingLevel2;

		margin-bottom: $spacing-s;
	}

	&__attributes {
		display: flex;
		flex-direction: column;

		.attribute {
			display: flex;
			flex-direction: row;

			strong {
				@include captionBold;

			}

			p {
				@include captionRegular;

				display: inline-block;
				hyphens: auto;
				margin: 0;
				padding: 0;
			}
		}
	}

	&__eluates {
		display: flex;
		flex-direction: column;
		margin: $spacing-s 0 $spacing-s 0;

		p {
			@include captionRegular;

			display: inline-block;
			hyphens: auto;
			margin: 0;
			padding: 0;
		}
	}
}
