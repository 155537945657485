@import 'src/presentation/sass/inventory.scss';

.card-item-state {
	align-items: center;
	background-color: $primary-7;
	border-radius: 4px;
	display: flex;
	flex-basis: 100%;
	justify-content: space-between;
	min-height: 26px;
	padding: $spacing-s $spacing-s ($spacing-s - 2) ($spacing-s * 2);

	&--without-date {
		justify-content: flex-end;
	}

	&__date {
		align-items: center;
		display: inline-flex;
	}

	&__date-label {
		@include captionRegular;

		margin: ($spacing-xs / 2) 0 0 0;
		padding: 0;
	}

	.icon {
		height: 16px;
		margin-right: $spacing-s;
		width: 16px;
	}
}
