@import 'src/presentation/sass/inventory.scss';

.view-layout {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: stretch;

	> .view-layout-section {
		margin-bottom: ($spacing-s * 2);

		&:last-child {
			margin-bottom: 0;
		}

		&.view-layout-section--content-stretched {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-top: ($spacing-m * -1);
		}
	}
}
