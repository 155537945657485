@import 'inventory.scss';

.nav-element-primary-link {
	&--disabled {
		@include cursorDisabled;
	}

	.nav-element-primary {
		width: 80px;
		height: 80px;
		background-color: $white;
		outline: none;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		position: relative;

		.icon {
			width: 32px;
			height: 32px;
			color: $primary-50;
			z-index: $zIndex-2;
		}

		&__label {
			@include navigationDefault;

			color: $primary-50;
			margin-left: ($spacing-s * 2);
			z-index: $zIndex-2;
		}

		&--open {
			width: 280px;
			justify-content: flex-start;

			.icon {
				margin-left: ($spacing-s * 3);
			}
		}

		&--active:not(.nav-element-primary--disabled) {
			.icon,
			.nav-element-primary__label {
				color: $primary-100;
			}

			.nav-element-primary__active-element {
				width: 70px;
				height: 80px;
				position: absolute;
				right: 0;
				background-color: $background-light;
				border-bottom-left-radius: 8px;
				border-top-left-radius: 8px;
				z-index: $zIndex-1;
			}

			&.nav-element-primary--open {
				.nav-element-primary__active-element {
					width: 272px;
				}
			}

		}

		&--disabled {
			@include cursorDisabled;

			.icon,
			.nav-element-primary__label {
				color: $primary-30;
			}
		}

		&:active,
		&:hover {
			.icon,
			.nav-element-primary__label {
				color: $primary-110;
			}
		}
	}
}
