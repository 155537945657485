@import 'inventory.scss';

.footer {

	padding: (($spacing-m * 5) + $spacing-xs) $spacing-m ($spacing-s * 3);
	background-image: url('../../../../assets/logo/logo-navigation-footer.webp');
	background-repeat: no-repeat;
	background-position: center;

	&__navigation {
		display: flex;
		justify-content: flex-end;
		align-content: stretch;

		&__item {
			@include footerLinkDefault;

			margin-right: ($spacing-s * 5);

			&:last-child {
				margin-right: 0;
			}

			&:hover,
			&:active {
				@include footerLinkHover;
			}
		}
	}
}
