@import 'inventory.scss';

.card-maintenance-log-list {
	background-color: $background-white;
	border-radius: 8px;
	box-shadow: 0 6px 20px 0 transparentize($primary-100, 0.9);
	display: flex;
	position: relative;

	&__content {
		align-items: center;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: ($spacing-s * 3) ($spacing-s * 2) ($spacing-s * 2) ($spacing-s * 2);
		width: 100%;

		@include mediaQuery($breakpoint-sm, down) {
			.card-item-header__content {
				max-width: 100%;
			}
		}
	}

	.card-item-state {
		margin-top: ($spacing-s * 3);
	}
}
