@import 'inventory.scss';

.badge {
	align-items: center;
	display: flex;
	justify-content: center;

	box-sizing: border-box;

	border-radius: 12px;
	height: 24px;
	width: 24px;

	@include mediaQuery($breakpoint-sm, down) {
		min-height: 18px;
		min-width: 18px;
	}

	&--red {
		@include badgeTextLight;
	}

	&--light-blue {
		@include badgeTextLight;
		background-color: $text-50;
	}

	&--primary {
		@include badgeTextLight;
		background-color: $primary-100;
	}
}
