@import 'inventory.scss';

.main-layout-section {
	&--section-topbar,
	&--section-topbar-not-fixed {
		background-color: var(--topbar-background);
		flex-shrink: 0;
		flex-grow: 0;
		margin: 0 (($spacing-s * 3) * -1);
		padding: 0 ($spacing-s * 3);
		position: sticky;
		left: 0;
		top: 0;
		z-index: $zIndex-101;
	}

	&--section-topbar-not-fixed {
		position: static;
	}

	&--width-narrow {
		align-self: center;
		width: 45.5%;
	}

	&--section-main {
		flex-shrink: 1;
		flex-grow: 1;
	}

	&--section-footer {
		flex-shrink: 0;
		flex-grow: 0;
	}

	.card-numeric-batch-collection {
		margin-top: -25px;
	}
}
