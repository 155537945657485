@import 'src/presentation/sass/inventory.scss';

.column-layout {
	display: flex;
	flex-direction: row;
	align-content: stretch;

	&.column-layout--mode-align-center {
		justify-content: center;

		+ .column-layout--mode-align-center {
			margin-top: ($spacing-s * 3);
		}
	}

	&.column-layout--mode-align-right {
		justify-content: flex-end;
	}

	> .column-layout-section {
		margin-right: ($spacing-s * 2);
		text-align: left;

		&:last-child {
			margin-right: 0;
		}

		&--mode-fixed {
			flex-grow: 0;
			flex-shrink: 0;
		}

		&--mode-flex {
			flex-grow: 1;
			flex-shrink: 1;
		}

		&--mode-align-right {
			padding-bottom: 0;
			margin-left: auto;
		}
	}
}
