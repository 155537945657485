@import 'inventory.scss';

.card-greeting {
	display: flex;
	flex-direction: column;
	min-height: 352px;
	justify-content: center;
	align-items: center;
	z-index: $zIndex-1;

	background-image: url('assets/card-greeting-background.svg');
	background-repeat: no-repeat;
	background-size: contain;
	content: '';
	width: 100%;
	height: 100%;
	background-position: center;

	&__greeting {
		@include greeting;

		margin: 0;
		padding: 0;
	}

	&__claim {
		@include paragraphCentered;

		margin: 0;
		padding: 0;
	}
}
