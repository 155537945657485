@import 'inventory.scss';

.button-toggle-set {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: auto;
	height: 44px;
	border-radius: 4px;
	border: solid 2px $primary-50;
	background-color: $white;

	@include mediaQuery($breakpoint-sm, down) {
		height: 36px;
	}

	.button-toggle {
		flex-grow: 1;
		flex-basis: 50%;

		&:first-child {
			margin-left: ($spacing-xs - 2);
		}

		&:last-child {
			margin-right: ($spacing-xs - 2);

			@include mediaQuery($breakpoint-sm, down) {
				margin-right: 0;
			}
		}
	}
}
