@import 'inventory.scss';

.card-sequence-detail {
	background-color: $background-white;
	border-radius: 8px;

	&__content {
		align-items: center;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: $spacing-m ($spacing-s * 2) ($spacing-s * 2) ($spacing-s * 2);
		width: calc(100% - #{$spacing-m});

		.card-item-sequence-attributes {
			margin-top: $spacing-m;
		}

		.card-item-sequence-action {
			margin-top: $spacing-s;
		}
	}
}
