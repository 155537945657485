@import 'src/presentation/sass/inventory.scss';

.modal-dialogue-section {
	.column-layout {
		flex-wrap: wrap;

		.column-layout-section--mode-fixed {
			margin: 0 $spacing-s ($spacing-s * 2) $spacing-s;
			max-width: calc(100% - #{$spacing-m * 2});
		}
	}

	&--section-main {
		display: inline-flex;
		flex-direction: column;
		flex-shrink: 1;
		flex-grow: 1;

		padding: ($spacing-s * 5);

		overflow: hidden;
		overflow-y: auto;

		@include mediaQuery($breakpoint-sm, down) {
			padding: ($spacing-s * 5) ($spacing-s * 3) ($spacing-s * 5) ($spacing-s * 3);
		}
	}

	&--section-footer {
		flex-shrink: 0;
		flex-grow: 0;

		padding: ($spacing-xs * 7) ($spacing-s * 5);

		@include mediaQuery($breakpoint-sm, down) {
			padding: ($spacing-xs * 7) ($spacing-s * 3) ($spacing-xs * 7) ($spacing-s * 3);
		}
	}
}
