.tab-bar-item {
	background: transparent;
	cursor: pointer;
	color: #5985b3;
	font-size: 16px;
	line-height: 20px;
	font-weight: 600;

	&__label {
		padding-right: 16px;
	}

	&:hover:not([disabled]) {
		color: #194c80;
		border-bottom: 4px solid #3dedd6;
	}

	&--is-active {
		color: #194c80;
		border-bottom: 4px solid #3dedd6;
	}
}
