@import 'src/presentation/sass/inventory.scss';

.card-nuclide-list-item {
	display: flex;
	flex-direction: row;
	flex-grow: 1;

	&__card {
		background-color: $white;
		display: flex;
		position: relative;
		margin-bottom: 4px;
		width: 100%;

		&__content {
			@include captionSemiBold;
			align-items: center;
			display: inline-flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 0 ($spacing-s * 2);
			width: 100%;
			height: $spacing-s * 8;
			line-height: $spacing-s + 3;
		}

		&.card-nuclide-list-item__card--disabled {
			background-color: $primary-5;
			box-shadow: none;

			.card-cyclotron-product-list-item__content {
				@include cursorDisabled;

				user-select: none;
			}
		}

		&.card-nuclide-list-item__card--completely-applied {
			background-color: $primary-5;
		}

		.card-nuclide-list-item__card--header {
			margin-left: ($spacing-s * 2);

			@include mediaQuery($breakpoint-sm, down) {
				.card-cyclotron-product-list-item-header__content {
					margin-right: $spacing-s;

					.list-item-header__schedule-tag {
						flex-shrink: 0;
					}
				}
			}
		}

		.card-item-state {
			margin-top: $spacing-xs;
		}
	}
}
