@import 'inventory.scss';

.generator-table-head {
	&__row {
		&__cell {
			position: relative;
			padding: $spacing-xs ($spacing-xs * 3) $spacing-xs;

			white-space: nowrap;
			border-bottom-left-radius: 8px;

			&__value {
				display: block;
			}

			&--major {
				@include captionBold;
				background-color: $background-light;
			}

			&--align-right {
				padding-right: ($spacing-s * 3);
				text-align: right;
			}
		}
	}
}
