@import 'inventory.scss';

.root-layout-section {
	display: block;
	width: 100%;

	.topbar-layout-section {
		margin: 0 (($spacing-s * 3) * -1);
	}

	.navigation-primary {
		position: fixed;
		left: 0;
		top: 0;
		height: 100vh;
		z-index: $zIndex-1000;

		~ .main-layout {
			margin-left: $navWidthCollapsed;
			width: calc(100% - #{$navWidthCollapsed});
		}

		&.navigation-primary--open {
			@include mediaQuery($breakpoint-xl) {
				~ .main-layout {
					margin-left: $navWidthExpanded;
					width: calc(100% - #{$navWidthExpanded});
				}
			}
		}
	}

	.main-layout {
		position: relative;
		box-sizing: border-box;
		min-height: 100vh;
		padding: 0 ($spacing-s * 3);
	}
}
