@import 'inventory.scss';

.card-item-attributes-action {
	background-color: $primary-5;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	padding: ($spacing-s * 2) ($spacing-s * 2) ($spacing-xs * 3) ($spacing-s * 2);
	margin-top: $spacing-s;
	text-align: left;
	width: 100%;

	&__title {
		@include captionRegular;

		margin: 0;
		padding: 0;
	}

	.button-secondary {
		margin-left: auto;

		.button-secondary__button {
			background-color: transparent;
		}
	}
}
