@import 'src/presentation/sass/inventory.scss';

.tag {
	@include tagTextLight;

	border-radius: 4px;
	min-width: 38px;
	padding: $spacing-xs $spacing-s;
	text-align: center;
	user-select: none;

	&--neutral {
		background-color: $secondary-100;
	}

	&--negative {
		background-color: $accent-error-100;
	}

	&--positive {
		background-color: $accent-success-100;
	}

	&--warn {
		background-color: $accent-warning-100;
	}

	&--info {
		@include tagTextDark;

		align-items: center;
		background-color: $primary-10;
		border-radius: 16px;
		display: flex;
		padding: $spacing-xs ($spacing-s + 2) $spacing-xs ($spacing-s + 2);

		.icon {
			margin-right: $spacing-xs;
		}
	}
}
