@import 'inventory.scss';

.input-file__file {
	@include formValueDefault;

	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: ($spacing-s * 2);
	outline: 0;
	padding-top: ($spacing-xs * 3);

	.icon {
		cursor: pointer;
		margin: 0 ($spacing-xs * 3) 0 ($spacing-s * 2);
	}
}
