/*** zIndex VALUES ***/

$zIndex-1: 1;
$zIndex-2: 2;
$zIndex-5: 5;
$zIndex-20: 20;
$zIndex-99: 99;
$zIndex-100: 100;
$zIndex-101: 101;
$zIndex-500: 500;
$zIndex-1000: 1000;
$zIndex-2000: 2000;
$zIndex-2001: 2001;
