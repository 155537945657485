@import 'inventory.scss';

.card-item-membership-attributes {
	background-color: $primary-5;
	border-radius: 4px;
	padding: ($spacing-s * 2);
	text-align: left;
	width: 100%;
	margin-top: ($spacing-s * 3);

	strong {
		@include captionBold;

	}

	p {
		@include captionRegular;

		hyphens: auto;
		margin: 0;
		padding: 0;
	}

	.card-item-membership-attributes__attribute {
		display: flex;
		flex-direction: column;
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	.attribute {
		display: inline-flex;
	}
}
