@import 'inventory.scss';

.error {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - #{$spacing-s * 6});

	.site-title {
		margin-bottom: ($spacing-s * 3);
	}

	.notification-bar + .notification-bar {
		margin-top: ($spacing-s * 3);
	}
}
