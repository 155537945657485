@import 'src/presentation/sass/inventory.scss';

.input-file {
	background-color: $white;

	&:hover:not(.disabled) {
		button {
			border: solid 1px $primary-110;
			border-radius: 24px;
			margin: -1px;

			@include mediaQuery($breakpoint-sm, down) {
				border: none;
			}

			.button-secondary__text {
				@include buttonLabelHover;
			}

			.icon {
				color: $primary-110;
			}
		}

		.input-file__label {
			margin-right: ($spacing-s * 2);
		}
	}

	&:active:not(.disabled) {
		button {
			height: 44px;
			margin: -2px;
		}

		.input-file__label {
			margin-right: (($spacing-s * 2) + 2);
		}
	}

	&__input-wrapper {
		align-items: center;
		display: flex;
		justify-content: space-between;
		position: relative;
	}

	&__label {
		@include formValuePlaceholder;

		display: block;
		margin: ($spacing-xs * 3) ($spacing-s * 2) ($spacing-xs * 3) 0;
		pointer-events: none;
		white-space: nowrap;
		z-index: $zIndex-20;
	}

	input {
		bottom: 0;
		cursor: pointer;
		display: block;
		left: 0;
		margin: 0;
		opacity: 0;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: $zIndex-101;
		width: 100%;
	}

	&.disabled {
		.input-file__input-wrapper {
			@include cursorDisabled;
		}

		button {
			display: none;

			.button-secondary__text {
				@include buttonLabelDisabled;
			}

			.icon {
				color: $primary-30;
			}
		}
	}

	&.summarized {
		background-color: transparent;
		padding: ($spacing-xs * 3) 0;

		.input-file__input-wrapper {
			display: none;

			& + .input-file__file {
				margin-top: 0;
				padding-top: 0;
			}
		}
	}
}
