@import 'inventory.scss';

.report-card-device-with-sequences {

	border-radius: 8px;
	display: flex;
	position: relative;

	&__header {
		&--report-view {
			background-color: unset;
		}
	}

	&__content {
		align-items: center;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: $spacing-s;
		width: 100%;

		@include mediaQuery($breakpoint-sm, down) {
			.card-item-header__content {
				max-width: 100%;
			}
		}

		.card-item-header {
			.illustration {
				width: 48px;
				height: 48px;
			}
		}
	}

	.card-collection-layout {
		flex-basis: 100%;
		margin-top: $spacing-s;

		.card-document {
			&__content {
				padding: 8px;

				.date-picker {
					margin-left: 8px;

					input {
						padding: 4px;
					}

				}
			}
		}
	}
}
