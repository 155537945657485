@import 'inventory.scss';

.card-cyclotron-product-detail {
	background-color: $background-white;
	border-radius: 8px;
	display: flex;
	position: relative;

	&.card-cyclotron-product-detail--shadow {
		box-shadow: 0 6px 20px 0 transparentize($primary-100, 0.9);
	}

	&.card-cyclotron-product-detail--nuclide {
		margin-top: $spacing-s * 5;
	}

	&.card-cyclotron-product-detail--disabled {
		@include cursorDisabled;
	}

	&__content {
		align-items: center;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: ($spacing-s * 3) ($spacing-s * 2) ($spacing-s * 3) ($spacing-s * 2);
		width: 100%;
	}

	.card-item-cyclotron-product-detail-attributes {
		margin-top: ($spacing-s * 3);
	}
}
