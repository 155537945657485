@import 'inventory.scss';

.card-item-sequence {
	align-items: center;
	background-color: transparent;
	display: flex;
	justify-content: space-between;

	&__sparkline {
		margin-right: ($spacing-s * 3);
	}
}
